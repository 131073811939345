<template>

    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.333252" y="0.727783" width="24" height="24" rx="12" fill="#D2D2D2"/>
</svg>

</template>

<script>
export default {
    
}
</script>