<template>
    <div>
        <v-app >
            
            <div class="fondo-public-tracking" style="position: absolute; height: 100vh; width: 100vw">
                <!--<v-img contain src="/img/capa_vector_2.png"></v-img>-->
            </div>
            
            <v-container class="tracking-landing">
                
                    <v-card class="mt-4 pa-4 col-sm-10 offset-sm-1"  >
                        <v-card-text>
                            <v-card  color="#f9f9f9" >
                                <v-card-title>
                                    <p class="text-h5">Seguimiento de envío</p>
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <div class="d-flex align-center">
                                                <span class="text-h4 mr-2 black--text">
                                                    N° {{shipping_number}}
                                                </span>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <button v-on="on" type="button" v-clipboard:copy="shipping_number"> <v-icon>mdi-content-copy</v-icon></button>
                                                    </template>
                                                    <span>Copiar</span>
                                                </v-tooltip>
                                            </div>
                                        </v-col>
                                        <v-col>
                                            <p class="text-h6 black--text text-right">
                                                Última actualización:
                                            </p>
                                            <p class="text-h6  text-right">
                                                {{$moment(fecha_actualizacion).format('LLLL')}}
                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-text >
                                    <v-row  >
                                        <v-col cols="12" md="12" >
                                            <p class="text-h5 black--text">Datos de envío</p>
                                            <hr>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td><p class="text-h6">Distribuido por:</p></td>
                                                        <td><p class="text-h6 black--text ml-2">{{carrier_name}}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td><p class="text-h6">N° de envío:</p></td>
                                                        <td><p class="text-h6 black--text ml-2">{{shipping_number}}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td><p class="text-h6">N° de orden:</p></td>
                                                        <td><p class="text-h6 black--text ml-2">{{order_id}}</p></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-card-text>
                        <v-card-text style="position:relative;">
                            <div class="row">
                                <hr class="d-xs-none align-self-center" style="width: 100%; position:absolute;">
                                <div class="align-center col-sm-3 text-center">
                                    <div>
                                        <icono-en-preparacion :color="!!orderHistory.find(e => parseFloat(e.order_state.order) < 6) ? '#3598FE': '#BFBFBF'"></icono-en-preparacion>
                                    </div>
                                    <div>
                                        <icono-estado-activo v-if="!!orderHistory.find(e => parseFloat(e.order_state.order) < 6)" style="margin: 15px 15px" ></icono-estado-activo>
                                        <icono-estado-inactivo v-if="!orderHistory.find(e => parseFloat(e.order_state.order) < 6)" style="margin: 20px 15px"></icono-estado-inactivo>
                                    </div>
                                    <div>
                                        <span :class="['text-h6', 'black--text', !!orderHistory.find(e => parseFloat(e.order_state.order) < 6) ? '' : '']">En preparación</span>
                                    </div>
                                    <div>
                                        <span v-if="!!orderHistory.find(e => parseFloat(e.order_state.order) < 6)" class="text-h6 black--text ">{{$moment(orderHistory.find(e => parseFloat(e.order_state.order) < 6).created_at).format('DD/MM/YYYY')}}</span>
                                    </div>
                                </div>
                                <!-- <hr class="d-xs-none align-self-center" style="width: 100px"> -->
                                <div class="align-center col-sm-3 text-center">
                                    <div>
                                        <icono-retirado :color="!!orderHistory.find(e => e.order_state.name == 'Entregado a Transporte') ? '#3598FE': '#BFBFBF'"></icono-retirado>
                                    </div>
                                    <div>
                                        <icono-estado-activo v-if="!!orderHistory.find(e => e.order_state.name == 'Entregado a Transporte')" style="margin: 15px 15px" ></icono-estado-activo>
                                        <icono-estado-inactivo v-if="!orderHistory.find(e => e.order_state.name == 'Entregado a Transporte')" style="margin: 20px 15px"></icono-estado-inactivo>
                                    </div>
                                    <div>
                                        <span :class="['text-h6', 'black--text', !!orderHistory.find(e => e.order_state.name == 'Entregado a Transporte') ? '' : '']">Retirado por operador</span>
                                    </div>
                                    <div>
                                        <span v-if="!!orderHistory.find(e => e.order_state.name == 'Entregado a Transporte')" class="text-h6 black--text ">{{$moment(orderHistory.find(e => e.order_state.name == 'Entregado a Transporte').created_at).format('DD/MM/YYYY')}}</span>
                                    </div>
                                </div>
                                <!-- <hr class="d-xs-none align-self-center " style="width: 100px"> -->
                                <div class="align-center col-sm-3 text-center">
                                    <div>
                                        <icono-reparto :color="!!orderHistory.find(e => e.order_state.name == 'En Ruta') ? '#3598FE': '#BFBFBF'"></icono-reparto>
                                    </div>
                                    <div>
                                        <icono-estado-activo v-if="!!orderHistory.find(e => e.order_state.name == 'En Ruta')" style="margin: 15px 15px" ></icono-estado-activo>
                                        <icono-estado-inactivo v-if="!orderHistory.find(e => e.order_state.name == 'En Ruta')" style="margin: 20px 15px"></icono-estado-inactivo>
                                    </div>
                                    <div>
                                        <span :class="['text-h6', 'black--text', !!orderHistory.find(e => e.order_state.name == 'En Ruta') ? '' : '']">En reparto</span>
                                    </div>
                                    <div>
                                        <span v-if="!!orderHistory.find(e => e.order_state.name == 'En Ruta')" class="text-h6 black--text ">{{$moment(orderHistory.find(e => e.order_state.name == 'En Ruta').created_at).format('DD/MM/YYYY')}}</span>
                                    </div>
                                </div>
                                <!-- <hr class="d-xs-none align-self-center" style="width: 100px"> -->
                                <div class="align-center col-sm-3 text-center">
                                    <div>
                                        <icono-entregado :color="!!orderHistory.find(e => e.order_state.name == 'Entregado a Cliente') ? '#3598FE': '#BFBFBF'"></icono-entregado>
                                    </div>
                                    <div>
                                        <icono-estado-activo v-if="!!orderHistory.find(e => e.order_state.name == 'Entregado a Cliente')" style="margin: 15px 15px" ></icono-estado-activo>
                                        <icono-estado-inactivo v-if="!orderHistory.find(e => e.order_state.name == 'Entregado a Cliente')" style="margin: 20px 15px"></icono-estado-inactivo>
                                    </div>
                                    <div>
                                        <span :class="['text-h6', 'black--text', !!orderHistory.find(e => e.order_state.name == 'Entregado a Cliente') ? 'font-weight-bold' : '']">Entregado</span>
                                    </div>
                                    <div>
                                        <span v-if="!!orderHistory.find(e => e.order_state.name == 'Entregado a Cliente')" class="text-h6 black--text font-weight-bold">{{$moment(orderHistory.find(e => e.order_state.name == 'Entregado a Cliente').created_at).format('DD/MM/YYYY')}}</span>
                                    </div>
                                </div>    
                            </div>
                            <!-- <div class="d-flex  justify-center">
                                <div class="d-flex flex-column align-center">
                                    <icono-en-preparacion :color="!!orderHistory.find(e => parseFloat(e.order_state.order) < 6) ? '#3598FE': '#BFBFBF'"></icono-en-preparacion>
                                    <icono-estado-activo v-if="!!orderHistory.find(e => parseFloat(e.order_state.order) < 6)" style="margin: 15px 15px" ></icono-estado-activo>
                                    <icono-estado-inactivo v-if="!orderHistory.find(e => parseFloat(e.order_state.order) < 6)" style="margin: 20px 15px"></icono-estado-inactivo>
                                    <span :class="['text-h6', 'black--text', !!orderHistory.find(e => parseFloat(e.order_state.order) < 6) ? 'font-weight-bold' : '']">En preparación</span>
                                    <span v-if="!!orderHistory.find(e => parseFloat(e.order_state.order) < 6)" class="text-h6 black--text font-weight-bold">{{$moment(orderHistory.find(e => parseFloat(e.order_state.order) < 6).created_at).format('DD/MM/YYYY')}}</span>
                                </div>
                                <hr class="d-xs-none align-self-center" style="width: 100px">
                                <div class="d-flex flex-column align-center">
                                    <icono-retirado :color="!!orderHistory.find(e => e.order_state.name == 'Entregado a Transporte') ? '#3598FE': '#BFBFBF'"></icono-retirado>
                                    <icono-estado-activo v-if="!!orderHistory.find(e => e.order_state.name == 'Entregado a Transporte')" style="margin: 15px 15px" ></icono-estado-activo>
                                    <icono-estado-inactivo v-if="!orderHistory.find(e => e.order_state.name == 'Entregado a Transporte')" style="margin: 20px 15px"></icono-estado-inactivo>
                                    <span :class="['text-h6', 'black--text', !!orderHistory.find(e => e.order_state.name == 'Entregado a Transporte') ? 'font-weight-bold' : '']">Retirado por operador</span>
                                    <span v-if="!!orderHistory.find(e => e.order_state.name == 'Entregado a Transporte')" class="text-h6 black--text font-weight-bold">{{$moment(orderHistory.find(e => e.order_state.name == 'Entregado a Transporte').created_at).format('DD/MM/YYYY')}}</span>
                                </div>
                                <hr class="d-xs-none align-self-center " style="width: 100px">
                                <div class="d-flex flex-column align-center">
                                    <icono-reparto :color="!!orderHistory.find(e => e.order_state.name == 'En Ruta') ? '#3598FE': '#BFBFBF'"></icono-reparto>
                                    <icono-estado-activo v-if="!!orderHistory.find(e => e.order_state.name == 'En Ruta')" style="margin: 15px 15px" ></icono-estado-activo>
                                    <icono-estado-inactivo v-if="!orderHistory.find(e => e.order_state.name == 'En Ruta')" style="margin: 20px 15px"></icono-estado-inactivo>
                                    <span :class="['text-h6', 'black--text', !!orderHistory.find(e => e.order_state.name == 'En Ruta') ? 'font-weight-bold' : '']">En reparto</span>
                                    <span v-if="!!orderHistory.find(e => e.order_state.name == 'En Ruta')" class="text-h6 black--text font-weight-bold">{{$moment(orderHistory.find(e => e.order_state.name == 'En Ruta').created_at).format('DD/MM/YYYY')}}</span>
                                </div>
                                <hr class="d-xs-none align-self-center" style="width: 100px">
                                <div class="d-flex flex-column align-center">
                                    <icono-entregado :color="!!orderHistory.find(e => e.order_state.name == 'Entregado a Cliente') ? '#3598FE': '#BFBFBF'"></icono-entregado>
                                    <icono-estado-activo v-if="!!orderHistory.find(e => e.order_state.name == 'Entregado a Cliente')" style="margin: 15px 15px" ></icono-estado-activo>
                                    <icono-estado-inactivo v-if="!orderHistory.find(e => e.order_state.name == 'Entregado a Cliente')" style="margin: 20px 15px"></icono-estado-inactivo>
                                    <span :class="['text-h6', 'black--text', !!orderHistory.find(e => e.order_state.name == 'Entregado a Cliente') ? 'font-weight-bold' : '']">Entregado</span>
                                    <span v-if="!!orderHistory.find(e => e.order_state.name == 'Entregado a Cliente')" class="text-h6 black--text font-weight-bold">{{$moment(orderHistory.find(e => e.order_state.name == 'Entregado a Cliente').created_at).format('DD/MM/YYYY')}}</span>
                                </div>
                            </div> -->
                        </v-card-text>
                        
                        <v-card-text>
                            <v-row  >
                                <v-col cols="12" md="12" >
                                    <p class="text-h5  black--text">Historial de estados</p>
                                    <hr>
                                    <div v-for="(item,index) in orderHistory" :key="index" class="d-flex justify-space-between">
                                        <span class="text-h6">{{item.order_state.name}}</span>
                                        <span class="text-h6">
                                            <v-icon class="mr-2">mdi-calendar-month</v-icon>
                                            {{$moment(item.created_at).format('DD/MM/YYYY HH:mm')}}
                                        </span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

            </v-container> 
        </v-app>
    </div>
</template>

<script>
import IconoEnPreparacion from '@/views/oms/Ordenes/IconoEnPreparacion.vue';
import IconoEntregado from '@/views/oms/Ordenes/IconoEntregado.vue';
import IconoRetirado from '@/views/oms/Ordenes/IconoRetirado.vue';
import IconoReparto from '@/views/oms/Ordenes/IconoReparto.vue';
import IconoEstadoActivo from '@/views/oms/Ordenes/IconoEstadoActivo.vue';
import IconoEstadoInactivo from '@/views/oms/Ordenes/IconoEstadoInactivo.vue';
export default {
    components: {
        IconoEnPreparacion,
        IconoRetirado,
        IconoReparto,
        IconoEntregado,
        IconoEstadoInactivo,
        IconoEstadoActivo
    },
    data(){
        return {
            shipping_number: '',
            fecha_actualizacion: '',
            orderHistory: [],
            carrier_name: '',
            order_id: '',
            shipmentHistory: [],
        }
    },
    methods: {
        fetchData(){
            var vm = this;
            this.axios({
                url: 'shippings/tracking/'+this.$route.params.hash
            }).then( response => {
                vm.shipping_number = response.data.shipping_number;
                vm.fecha_actualizacion = response.data.updated_at;
                vm.orderHistory = response.data.order_history.reverse();
                vm.carrier_name = response.data.carrier_name;
                vm.order_id = response.data.order_id;
                vm.shipmentHistory = response.data.shipment_history;
            }).catch( error => {
                console.log(error);
                vm.$router.push({name: '404'});
            });
        },
    },
    watch: {
        $route(to, from) {
            this.fetchData();
        }
    },
    mounted(){
        this.fetchData();
    }
}
</script>

<style>
.fondo-public-tracking{
    background-image: url('/img/capa_vector_2.png') !important;
    background-size: auto 100%;
    -moz-background-size: auto 100%;
    -webkit-background-size: auto 100%;
    -o-background-size: auto 100%;
    background-position: left center;
    background-repeat: no-repeat;
}
.tracking-landing .text-h5,
.tracking-landing .text-h4,
.tracking-landing .text-h6{
    font-family: "Poppins", sans-serif !important;
}

</style>