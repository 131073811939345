<template>
<svg width="56" height="50" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 56 55" enable-background="new 0 0 56 55" xml:space="preserve">
<g>
		<path :fill="color" :stroke="color"  stroke-width="0.1" d="M51.5,31.2v-0.9h1.9v1.9h-0.9C52,32.1,51.5,31.7,51.5,31.2z
			 M55.2,29.3c0-2.3-1.7-4.2-3.9-4.5L50,19.2c0.4-0.1,0.7-0.5,0.7-0.9v-0.9c0-2-1.6-3.7-3.7-3.7h-6.4v-1.8c0-1-0.8-1.8-1.8-1.8H5.6
			c-1,0-1.8,0.8-1.8,1.8v15.6c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9V11.9c0,0,0,0,0,0h33.1c0,0,0,0,0,0v15.6
			c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9v-0.9h10.1c0,0,0,0,0,0l0,0c0,0,0,0,0,0c1.2,0,2.2,0.8,2.6,1.9h-2.6
			c-0.5,0-0.9,0.4-0.9,0.9v1.8c0,1.5,1.2,2.7,2.7,2.7h0.9v3.7h-2.2c-0.8-2.1-2.8-3.7-5.2-3.7c-2.4,0-4.4,1.5-5.2,3.7h-0.3v-6.4
			c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9v6.4H20.9c-0.8-2.1-2.8-3.7-5.2-3.7c-2.4,0-4.4,1.5-5.2,3.7H5.6c0,0,0,0,0,0v-1.8h3.7
			c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9H1c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h2.8v1.8c0,1,0.8,1.8,1.8,1.8h4.6
			c0,0,0,0,0,0c0,3,2.5,5.5,5.5,5.5s5.5-2.5,5.5-5.5c0,0,0,0,0,0h19.3c0,0,0,0,0,0c0,3,2.5,5.5,5.5,5.5c3,0,5.5-2.5,5.5-5.5
			c0,0,0,0,0,0h2.8c0.5,0,0.9-0.4,0.9-0.9V29.3z M40.5,15.6H47c1,0,1.8,0.8,1.8,1.8v0h-8.3V15.6z M40.5,24.8v-5.5h7.6l1.4,5.5H40.5z
			 M15.7,43.1c-2,0-3.7-1.7-3.7-3.7c0-2,1.7-3.7,3.7-3.7c2,0,3.7,1.7,3.7,3.7C19.4,41.5,17.8,43.1,15.7,43.1z M46,43.1
			c-2,0-3.7-1.7-3.7-3.7c0-2,1.7-3.7,3.7-3.7c2,0,3.7,1.7,3.7,3.7C49.7,41.5,48.1,43.1,46,43.1z"/>
		<path :fill="color" d="M15.7,37.7c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8S16.7,37.7,15.7,37.7z"/>
		<path :fill="color" d="M46,37.7c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8S47,37.7,46,37.7z"/>
		<path :fill="color" d="M35.9,34H23.1c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h12.9c0.5,0,0.9-0.4,0.9-0.9
			C36.8,34.4,36.4,34,35.9,34z"/>
		<path :fill="color" d="M13.9,30.3h-11c-0.5,0-0.9,0.4-0.9,0.9S2.4,32,2.9,32h11c0.5,0,0.9-0.4,0.9-0.9S14.4,30.3,13.9,30.3z"/>
		<path :fill="color" d="M30.1,18.6c-0.3-0.3-0.9-0.3-1.2,0l-7.7,7.7l-4-4c-0.3-0.3-0.9-0.3-1.2,0c-0.3,0.3-0.3,0.9,0,1.2l4.6,4.6
			c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.4-0.1,0.6-0.3l8.3-8.3C30.4,19.5,30.4,19,30.1,18.6z"/>
	 
</g>
</svg>

</template>



<script>
export default {
    props: {
        color: {
            type: String,
            default: '#BFBFBF'
        }
    }
}
</script>