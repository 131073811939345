<template>
    <svg width="35" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d)">
<rect x="4.99988" y="1.72778" width="24" height="24" rx="12" fill="#3598FE"/>
</g>
<path d="M15.563 18.309C15.4458 18.4268 15.286 18.4926 15.1199 18.4926C14.9539 18.4926 14.7941 18.4268 14.6769 18.309L11.1389 14.7704C10.7717 14.4032 10.7717 13.8079 11.1389 13.4413L11.5819 12.9982C11.9491 12.631 12.5438 12.631 12.911 12.9982L15.1199 15.2071L21.0887 9.23836C21.4559 8.87115 22.0513 8.87115 22.4178 9.23836L22.8609 9.6814C23.2281 10.0486 23.2281 10.6439 22.8609 11.0105L15.563 18.309Z" fill="white"/>
<defs>
<filter id="filter0_d" x="-0.00012207" y="0.727783" width="34" height="34" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.207843 0 0 0 0 0.596078 0 0 0 0 0.996078 0 0 0 0.2 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
</defs>
</svg>

</template>

<script>
export default {
    
}
</script>